<template>
  <iframe
    ref="iframe"
    style="width: 100%"
    frameborder="0"
    :style="{ height: iframeHeight + 'px' }"
  ></iframe>
</template>

<script>
export default {
  props: {
    content: String
  },
  data() {
    return {
      iframeHeight: 0
    };
  },
  mounted() {
    this.viewArticle();
  },
  methods: {
    //iframe 保证样式隔离
    viewArticle() {
      const b = this.$refs.iframe.contentWindow.document.body;
      b.style.margin = "0";
      b.style.overflow = "hidden";
      b.innerHTML = this.content;
      setInterval(() => {
        //防止内容加载过慢导致高度不正确
        this.iframeHeight = b.offsetHeight + 1;
      }, 200);
    }
  }
};
</script>
<style lang="scss" scoped>
#article {
  padding: 0.3rem;
}
.iframe {
  width: 100%;
  min-height: 80vh;
}
</style>
