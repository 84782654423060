<!--
 * @Description: 
 * @Version: 2.0
 * @Date: 2022-07-01 14:32:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-08 13:41:48
-->
<!-- 申请材料  -->
<template>
  <div class="material-box">
    <div
      class="content"
      v-if="
        applyMaterialList &&
        applyMaterialList.length > 0
      "
    >
      <van-collapse
        v-if="applyMaterialList.length > 0"
        v-model="active"
        accordion
      >
        <van-collapse-item
          v-for="(item, i) in applyMaterialList"
          :key="i"
        >
          <div slot="title" class="table-tr">
            <div class="name">{{ item.materialName }}</div>
          </div>
          <van-cell
            v-for="m in 5"
            :key="m"
            :title="m | filterMaterial(m)"
            :label="showMaterial(item, m)"
          />
          <van-cell title="空白样表">
            <template #label>
              <a
                v-if="item.blankChartUrls"
                :href="item.blankChartUrls[0].url"
                :download="item.blankChartUrls[0].fileName"
                class="load-link"
              >
                {{ item.blankChartUrls[0].fileName }}
              </a>
              <span v-else>无</span>
            </template>
          </van-cell>
          <van-cell title="示例样表">
            <template #label>
              <a
                v-if="item.sampleChartUrls"
                :href="item.sampleChartUrls[0].url"
                :download="item.sampleChartUrls[0].fileName"
                class="load-link"
              >
                {{ item.sampleChartUrls[0].fileName }}
              </a>
              <span v-else>无</span>
            </template>
          </van-cell>
        </van-collapse-item>
      </van-collapse>
    </div>
    <van-empty v-else description="数据为空"> </van-empty>
  </div>
</template>

<script>
import { getApplyMaterias } from "@/api/policy/apartment";
export default {
  props: {
    detialInfo: Object,
  },
  data() {
    return {
      active: [],
      applyMaterialList: [],
    };
  },
  filters: {
    filterMaterial(v) {
      const types = {
        0: "申请材料",
        1: "材料形式",
        2: "材料类型",
        3: "来源渠道",
        4: "来源渠道说明",
        // 5: "纸质材料份数",
        5: "填报须知",
        // 7: "受理标准",
        6: "空白样表",
        7: "示例样表",
      };
      return types[v] || "";
    },
  },

  components: {},

  computed: {},

  created() {
    this.getList()
  },

  methods: {
    filterType(v) {
      const types = {
        1: "纸质",
        2: "电子",
        3: "纸质、电子",
      };
      return types[v] || "";
    },
    filterChannel(v) {
      const types = {
        9: "其他",
        10: "申请人自备",
        20: "政府部门核发",
      };
      return types[v] || "";
    },

    showMaterial(item, i) {
      const value = {
        0: item.materialName,
        1: (function () {
          const types = {
            1: "纸质",
            2: "电子",
            3: "纸质、电子",
          };
          return types[item.materialForm];
        })(),
        2: (function () {
          const types = {
            1: "原件",
            2: "复印",
            3: "原件和复印件",
            4: "原件或复印件",
          };
          return types[item.materialType];
        })(),
        3: (function () {
          const types = {
            9: "其他",
            10: "申请人自备",
            20: "政府部门核发",
          };
          return types[item.channel] || "";
        })(),
        4: item.channelDesc,
        // 5: item.paperNum,
        5: item.fillInstruction,
        // 7: item.acceptCriteria,
        6: (function () {
          if (item.blankChartUrls) {
            return item.blankChartUrls[0].fileName || "";
          }
          // return item.blankChartUrls[0].fileName || "";
        })(),
        7: (function () {
          if (item.sampleChartUrls) {
            return item.sampleChartUrls[0].fileName || "";
          }
          // return item.sampleChartUrls[0].fileName || "";
        })(),
      };
      return value[i];
    },
    getList() {
      const itemId = this.$route.query.itemId;
      getApplyMaterias({
        itemId: itemId,
      }).then((res) => {
        this.applyMaterialList = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.material-box {
  width: 100%;
  display: flex;
  min-height: 3rem;
  flex-direction: column;
  .content {
    margin: 0.28rem 0.3rem 0 0.3rem;
    .title-box {
      width: 100%;
      height: 0.6rem;
      background: #f1f3fe;
      display: flex;
      justify-content: space-between;
      padding-right: 0.5rem;
      .charge-title {
        font-size: 0.26rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.6rem;
        padding: 0 0.35rem 0 0.3rem;
      }
    }
    .title {
      height: 0.6rem;
      background: #f8f8f8;
      display: flex;
      justify-content: space-between;
    }
    .collapse-content {
      padding: 0 0.35rem 0 0.3rem;
    }
    .show-box {
      display: flex;
      padding-top: 0.3rem;
      .label {
        font-size: 0.26rem;
        font-weight: 400;
        color: #666666;
        line-height: 0.28rem;
        width: 2.1rem;
      }
      .txt {
        width: 6.2rem;
        font-size: 0.26rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.28rem;
        word-break: break-all;
      }
    }
  }
}
</style>

<style lang="scss">
.van-collapse {
  .van-cell {
    padding: 0.1rem 0;
  }
}
.table-tr {
  display: flex;
  .name {
    white-space: pre-wrap;
    word-break: break-all;
  }
}
.load-link {
  color: #1e69c5;
}
</style>
