<!-- 事项详情 -->
<template>
  <div class="component">
    <div class="title">基本信息</div>
    <div class="basic-info">
      <van-cell
        v-for="i in 3"
        :key="i"
        :title="i | filterLabel(i)"
        :label="showTxt(i, detialInfo)"
      />
      <van-cell title="咨询方式">
        <template #label>
          <span
            v-if="detialInfo.consultationMode"
            v-html="formatBr(detialInfo.consultationMode)"
          ></span>
          <span v-else>无</span>
        </template>
      </van-cell>
      <van-cell title="监督投诉">
        <template #label>
          <span
            v-if="detialInfo.complainMode"
            v-html="formatBr(detialInfo.complainMode)"
          ></span>
          <span v-else>无</span>
        </template>
      </van-cell>
    </div>
    <div class="title">收费信息</div>
    <div class="charge-info" v-if="chargeInfoList && chargeInfoList.length > 0">
      <div class="collapse-content">
        <van-collapse v-model="active" accordion>
          <van-collapse-item v-for="(item, i) in chargeInfoList" :key="i">
            <div slot="title">
              <div class="name">{{ item.chargeName }}</div>
            </div>

            <van-cell v-for="m in 6" :key="m" :title="m | filterCharge(m)">
              <template #label>
                {{ showCharge(item, m) }}
              </template>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div v-else class="set-up">不收费</div>
    <div class="title">设定依据</div>
    <div class="set-up">{{ detialInfo.settingBasis }}</div>
    <!-- <div class="title">办理流程</div>
    <div class="process-box">
      <div class="box" v-if="handleProcessList && handleProcessList.length > 0">
        <div class="steps-box">
          <van-steps direction="vertical" :active="handleProcessList.length">
            <van-step v-for="(item, i) in handleProcessList" :key="i">
              <div>{{ item.stepName }}</div>
              <van-cell title="办事人">
                <template #default>
                  {{ item.handler || "-" }}
                </template>
              </van-cell>
              <van-cell title=" 办理时限">
                <template #default>
                  {{ showTime(item.timeLimit, item.timeLimitUnit) }}
                </template>
              </van-cell>
              <van-cell title="办理结果">
                <template #default>
                  {{ item.handleResult || "-" }}
                </template>
              </van-cell>
            </van-step>
          </van-steps>
        </div>
      </div>
      <van-empty v-else description="数据为空"> </van-empty>
    </div> -->
  </div>
</template>

<script>
import {
  getChargeInfoList,
  getHandleProcessList,
  getItemResultList
} from "@/api/policy/apartment";
export default {
  props: {
    detialInfo: Object
  },
  data() {
    return {
      active: [],
      chargeInfoList: [],
      handleProcessList: []
    };
  },
  filters: {
    filterLabel(i) {
      const labelVaule = {
        0: "事项名称",
        1: "事项类型",
        2: "办件类型",
        3: "实施主体",
        4: "咨询方式",
        5: "监督投诉"
        // 10: "设定依据",
      };
      return labelVaule[i];
    },
    filterCharge(i) {
      const labelVaule = {
        0: "收费项目",
        1: "收费标准",
        2: "收费依据",
        3: "是否减免",
        4: "减免依据",
        5: "备注"
      };
      return labelVaule[i];
    }
  },

  components: {},

  computed: {},

  created() {
    this.getHandleProcess();
    this.getChargeList();
  },

  methods: {
    showTime(t, u) {
      if (u == 4) {
        return "实时";
      } else if (u == 3) {
        return t + "个月";
      } else if (u == 2) {
        return t + "个自然日";
      } else if (u == 1) {
        return t + "个工作日";
      } else {
        return "--";
      }
    },
    // 查询办理流程列表
    getHandleProcess() {
      const itemId = this.$route.query.itemId;
      getHandleProcessList({
        itemId: itemId
      }).then((res) => {
        this.handleProcessList = res.data;
      });
    },
    showFree(i) {
      return i == 1 ? "是" : "否";
    },
    showTxt(i, detialInfo) {
      const txtVale = {
        0: detialInfo.itemName,
        1: (function () {
          const type = {
            1: "行政许可",
            2: "行政处罚",
            3: "行政强制",
            4: "行政征收",
            5: "行政给付",
            6: "行政检查",
            7: "行政确认",
            8: "行政奖励",
            9: "行政裁决",
            10: "其他类别事项",
            20: "公共服务"
          };
          return type[detialInfo.itemType];
        })(),
        2: (function () {
          const type = {
            1: "即办件",
            2: "承诺件"
          };
          return type[detialInfo.handleType];
        })(),
        3: detialInfo.applySubject,
        4: detialInfo.consultationMode,
        5: detialInfo.complainMode
      };
      return txtVale[i];
    },
    showCharge(item, i) {
      const value = {
        0: item.chargeName,
        1: item.chargeStandard,
        2: item.chargeBasis,
        3: item.freeCharge == 1 ? "是" : "否",
        4: item.freeStandard,
        5: item.remark
      };
      return value[i];
    },
    formatBr(text) {
      if (text) {
        return text.replace(/\n/g, "<br/>");
      } else {
        return text;
      }
    },
    getChargeList() {
      const itemId = this.$route.query.itemId;
      getChargeInfoList({
        itemId: itemId
      }).then((res) => {
        this.chargeInfoList = res.data;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.component {
  width: 100%;
  display: flex;
  flex-direction: column;
  .title {
    height: 0.36rem;
    font-size: 0.34rem;
    margin: 0.4rem 0 0.3rem 0.3rem;
    color: #333333;
  }
  .basic-info {
    margin: 0 0.3rem;
    .list-item {
      display: flex;
      flex-direction: row;

      .label {
        width: 2.1rem;
        text-align: center;
        font-size: 0.26rem;
        font-weight: 400;
        color: #666666;

        line-height: 0.6rem;
        // background: #f1f3fe;
        background: #f8f8f8;
        border-left: 1px solid #979797;
        border-top: 1px solid #979797;
      }
      .label-first {
        background: #f1f3fe;
      }

      .txt {
        width: 6.2rem;
        text-align: left;
        padding: 0 0.2rem;
        border-left: 1px solid #979797;
        border-top: 1px solid #979797;
        border-right: 1px solid #979797;
        font-size: 0.26rem;
        font-weight: 400;
        color: #666666;
        word-break: break-all;
        line-height: 0.6rem;
      }
    }
  }
  .charge-info {
    margin: 0 0.3rem;
    .title-box {
      width: 100%;
      height: 0.6rem;
      background: #f1f3fe;
      display: flex;
      justify-content: space-between;
      .charge-title {
        font-size: 0.26rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.6rem;
        padding: 0 0.35rem 0 0.3rem;
      }
    }
    .collapse-content {
      .collapse-box {
        display: flex;
        padding-top: 0.3rem;
        .label {
          font-size: 0.26rem;
          font-weight: 400;
          color: #666666;
          line-height: 0.28rem;
          width: 2.1rem;
        }
        .txt {
          width: 6.2rem;
          font-size: 0.26rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.28rem;
          word-break: break-all;
        }
      }
    }
  }
  .set-up {
    width: 100%;
    margin: 0 0.3rem;
    font-size: 0.26rem;
    font-weight: 400;
    color: #666666;
    line-height: 0.56rem;
    padding: 0 0.35rem 0 0.3rem;
    background: #fff;
    word-break: break-all;
    white-space: pre-wrap;
  }
}

.process-box {
  margin: 0 0 0.3rem 0.3rem;
  .box {
    .steps-box {
      .step-item {
        display: flex;
        flex-direction: row;
        .circle-line-box {
          .circle {
            width: 0.4rem;
            height: 0.4rem;
            background: #ffffff;
            border: 2px solid #3c37d2;
            border-radius: 50%;
            font-size: 0.24rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .line {
            margin: 0.1rem 0.13rem;
            width: 1px;
            min-height: 1rem;
            border: 1px solid #979797;
          }
        }
        .text-content {
          display: flex;
          flex-direction: column;
          .col {
            display: flex;
            flex-direction: row;
            // height: .36rem;
            line-height: 0.36rem;
            .step-name {
              font-size: 0.34rem;
              font-weight: 500;
              color: #3c37d2;
              line-height: 0.36rem;
              margin: 0 0.25rem;
            }
            .user,
            .time {
              width: 2.3rem;
              // height: .28rem;
              font-size: 0.26rem;
              font-weight: 400;
              color: #333333;
              line-height: 0.36rem;

              margin-right: 0.25rem;
            }
          }
          .col-s {
            width: 6rem;
            font-size: 0.26rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.36rem;
            margin-top: 0.2rem;
            margin-left: 0.25rem;
          }
        }
      }
    }
  }
}
</style>
