import request from "@/utils/request";

/* 查询申请材料 */
export function getApplyMaterias(params) {
  return request({
    url: "/policy/apartment/getApplyMaterias",
    method: "GET",
    params
  });
}
/* 查询收费信息列表 */
export function getChargeInfoList(params) {
  return request({
    url: "/policy/apartment/getChargeInfoList",
    method: "GET",
    params
  });
}
// 查询办理流程列表
export function getHandleProcessList(params) {
  return request({
    url: "/policy/apartment/getHandleProcessList",
    method: "GET",
    params
  });
}
// 查询办事项详情
export function getItemInfo(params) {
  return request({
    url: "/policy/apartment/getItemInfo",
    method: "GET",
    params
  });
}

// 查询办理结果列表
export function getItemResultList(params) {
  return request({
    url: "/policy/apartment/getItemResultList",
    method: "GET",
    params
  });
}