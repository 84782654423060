import { render, staticRenderFns } from "./relevant.vue?vue&type=template&id=2917a3ec&scoped=true"
import script from "./relevant.vue?vue&type=script&lang=js"
export * from "./relevant.vue?vue&type=script&lang=js"
import style0 from "./relevant.vue?vue&type=style&index=0&id=2917a3ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2917a3ec",
  null
  
)

export default component.exports