<!--
 * @Description: 
 * @Version: 2.0
 * @Date: 2022-07-01 14:32:33
 * @LastEditors: Fiona.xu
 * @LastEditTime: 2022-07-04 08:54:20
-->
<!-- 受理条件 -->
<template>
  <div class="term-box">
    <div
      class="paragraph"
      v-if="detialInfo"
      v-html="formatBr(detialInfo.acceptCondition)"
    ></div>
    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
export default {
  props: {
    detialInfo: Object,
  },
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    formatBr(text) {
      if (text) {
        return text.replace(/\n/g, "<br/>");
      } else {
        return text;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.term-box {
  width: 100%;
  display: flex;
  min-height: 3rem;
  flex-direction: column;
  .paragraph {
    font-size: 0.28rem;
    font-weight: 400;
    color: #333333;
    line-height: 0.42rem;
    padding: 0.3rem;
    word-break: break-all;
    text-align: justify;
  }
}
</style>
