<!--  -->
<template>
  <div class="page">
    <div class="title" v-if="detialInfo">{{ detialInfo.itemName }}</div>
    <div class="box-info" v-if="detialInfo">
      <div class="list-item">
        <div class="label">服务对象</div>
        <div class="txt">{{ showServiceObject(detialInfo.serviceObject) }}</div>
      </div>
      <div class="list-item">
        <div class="label">法定办结时限</div>
        <div class="txt">
          {{ showTime(detialInfo.legalTime, detialInfo.legalTimeUnit) }}
        </div>
      </div>
      <div class="list-item">
        <div class="label">承诺办结时限</div>
        <div class="txt">
          {{ showTime(detialInfo.promiseTime, detialInfo.promiseTimeUnit) }}
        </div>
      </div>
      <div class="list-item">
        <div class="label">办事地点</div>
        <div
          class="txt"
          v-html="formatBr(detialInfo.handleAddress || '')"
        ></div>
      </div>
      <div class="list-item">
        <div class="label">办理时间</div>
        <div class="txt" v-html="formatBr(detialInfo.handleTime || '')"></div>
      </div>
    </div>

    <div class="handle-box">
      <van-tabs v-model="current">
        <van-tab
          v-for="item in tabList"
          :title="item.name"
          :key="item.name"
        ></van-tab>
      </van-tabs>
      <div class="tab-content" v-if="detialInfo">
        <term v-if="current == 0" :detialInfo="detialInfo"></term>
        <material v-if="current == 1" :detialInfo="detialInfo"></material>
        <detail v-if="current == 2" :detialInfo="detialInfo"></detail>
        <relevant v-if="current == 3" :detialInfo="detialInfo"></relevant>
      </div>
      <van-empty v-else description="暂无数据" />
    </div>

    <div class="column-box" v-if="isSHowColumn(detialInfo)">
      <div class="column-name">{{ detialInfo.columnName }}</div>
      <div class="column-content" v-html="detialInfo.columnContent"></div>
    </div>

    <div class="btns" v-if="detialInfo">
      <van-button
        type="primary"
        round
        block
        disabled
        v-if="'1' != detialInfo.itemStatus"
      >
        <span>暂停办理</span>
      </van-button>
      <van-button
        type="primary"
        round
        block
        disabled
        v-else-if="'0' == detialInfo.online"
      >
        <span>当前办事项不支持线上办理</span>
      </van-button>
      <van-button
        type="primary"
        round
        block
        disabled
        v-else-if="'3' == detialInfo.declareStatus"
      >
        <span>不在申报时间内</span>
      </van-button>
      <!-- <van-button
        type="primary"
        round
        block
        disabled
        v-else-if="-1 == detialInfo.serviceObject.indexOf('1')"
      >
        <span>温馨提示：手机端暂不支持企业办事</span>
      </van-button> -->
      <van-button
        type="primary"
        round
        block
        @click="handleJumpOut()"
        v-else-if="'1' == detialInfo.onlinePlate"
      >
        <span>在线办理</span>
      </van-button>
      <van-button
        type="primary"
        round
        block
        disabled
        v-else-if="'1' == detialInfo.declareStatus"
      >
        <span>当前暂不可申报</span>
      </van-button>
      <van-button type="primary" round block @click="handleJump()" v-else>
        <span>在线办理</span>
      </van-button>
    </div>
    <LoginDialog :loginDialogShow.sync="loginDialogShow" />
  </div>
</template>

<script>
import {
  YOUNG_FORM_KEY_17,
  YOUNG_FORM_KEY_22,
  YOUNG_FORM_KEY,
} from "@/config/constant.js";
import { getItemInfo } from "@/api/policy/apartment";
import detail from "./components/detail.vue";
import term from "./components/term.vue";
import material from "./components/material.vue";
import relevant from "./components/relevant.vue";
import { Dialog } from "vant";
import LoginDialog from "@/components/LoginDialog";

export default {
  data() {
    return {
      current: 0,
      itemId: "", //事项ID
      detialInfo: null,
      isAuth: false,
      tabList: [
        {
          name: "受理条件",
        },
        {
          name: "申请材料",
        },
        {
          name: "事项详情",
        },
        {
          name: "常见问题",
        },
      ],
      showGoZhb: false,
      loginDialogShow: false,
    };
  },
  filters: {
    formatObject(val) {
      if (val) {
        const rules = {
          1: "自然人",
          2: "企业法人",
          3: "社团法人",
          5: "机关单位法人",
          6: "个体工商户",
        };
        return rules[val] || val;
      } else {
        return "--";
      }
    },
  },
  mounted() {
    this.getDetial();
  },

  components: { detail, term, material, relevant, LoginDialog },

  computed: {},
  methods: {
    /*法定办结时限  */
    showTime(t, u) {
      if (u == 4) {
        return "当场";
      } else if (u == 3) {
        return t + "个月";
      } else if (u == 2) {
        return t + "个自然日";
      } else if (u == 1) {
        return t + "个工作日";
      } else {
        return "--";
      }
    },
    /* 获取详情 */
    getDetial() {
      const itemId = this.$route.query.itemId;
      getItemInfo({
        itemId: itemId,
      }).then((res) => {
        let { data, success } = res;
        if (success) {
          this.detialInfo = data ? data : null;
        }
      });
    },
    //跳到外网办理
    handleJumpOut() {
      //非郑好办平台
      if (!this.$store.state.global.isZhengHaoBan) {
        Dialog.confirm({
          title: "提示",
          message:
            "温馨提示：请在“郑好办APP”中办理，详情说明请点击下方“办事指引”",
          cancelButtonText: "关闭",
        })
          .then((res) => {
            this.$router.push("/newsDetail/1672787212212637698");
          })
          .catch((e) => {});
        return;
      }
      //未登录
      if (!this.$store.state.global.isLogin) {
        // this.$store.dispatch("global/authLogin");
        this.loginDialogShow = true;
        return;
      }
      //未获取到实名认证信息
      if (!this.$store.state.global.userInfo) {
        this.$toast("未获取到实名认证信息");
        return;
      }

      if (!this.detialInfo.phoneExternalUrl) {
        this.$toast("正在与其他平台对接中，敬请期待");
        return;
      }
      if (
        !/(^http:\/\/)|(^https:\/\/)/.test(this.detialInfo.phoneExternalUrl)
      ) {
        Dialog.alert({
          message: `温馨提示：${this.detialInfo.phoneExternalUrl}`,
        });
        return;
      }
      window.location.href = this.detialInfo.phoneExternalUrl;
      return;
    },
    /* 在线办理 */
    handleJump() {
      //非郑好办平台
      if (!this.$store.state.global.isZhengHaoBan) {
        Dialog.confirm({
          title: "提示",
          message:
            "温馨提示：请在“郑好办APP”中办理，详情说明请点击下方“办事指引”",
          confirmButtonText: "查看指引",
          cancelButtonText: "关闭",
        })
          .then((res) => {
            this.$router.push("/newsDetail/1672787212212637698");
          })
          .catch((e) => {});
        return;
      }
      //未登录
      if (!this.$store.state.global.isLogin) {
        this.loginDialogShow = true;
        return;
      }
      //未获取到实名认证信息
      if (!this.$store.state.global.userInfo) {
        this.$toast("未获取到实名认证信息");
        return;
      }
      // 验证是否为法人
      if (-1 == this.detialInfo.serviceObject.indexOf("1")) {
        this.$toast("温馨提示：该服务为企业办事，请在电脑端登录办理");
        return;
      }

      const key = this.detialInfo.formKey;
      // 验证是否为青年人才
      if (this.validRentSubsidy(key)) {
        this.$router.push({
          path: "/question",
          query: {
            itemId: this.$route.query.itemId,
            formKey: key,
          },
        });
        return;
      }
      this.$router.push(`/apply/${this.$route.query.itemId}`);
    },
    /* change */
    handleChange(i) {
      this.current = i;
    },
    /* 服务对象 */
    showServiceObject(str) {
      if (str) {
        const arr = str.split(",");

        let strArr = [];
        arr.forEach((item) => {
          const type = {
            1: "自然人",
            2: "企业法人",
            3: "社团法人",
            5: "机关单位法人",
            6: "个体工商户",
          };
          strArr.push(type[item]);
        });
        return strArr.toString();
      } else {
        return "----";
      }
    },
    /* 验证办事项是否为青年人才购房补贴 */
    validRentSubsidy(val) {
      if (
        val === YOUNG_FORM_KEY_17 ||
        val === YOUNG_FORM_KEY_22 ||
        val === YOUNG_FORM_KEY
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 额外字段是否显示
    isSHowColumn(detialInfo) {
      let bol = false;
      if (!detialInfo) return false;
      if (Object.prototype.hasOwnProperty.call(detialInfo, "columnContent")) {
        bol = detialInfo.columnContent ? true : false;
      }
      if (Object.prototype.hasOwnProperty.call(detialInfo, "columnName")) {
        bol = detialInfo.columnName ? true : false;
      }
      return bol;
    },
    formatBr(text) {
      if (text) {
        return text.replace(/\n/g, "<br/>");
      } else {
        return text;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: 100%;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  .title {
    min-height: 0.48rem;
    font-size: 0.34rem;
    font-weight: 600;
    color: #333333;
    line-height: 0.48rem;
    text-align: center;
    margin: 0.2rem 0.3rem;
  }
  .box-info {
    margin: 0 0.3rem 0.2rem 0.3rem;

    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.2rem;
    .list-item {
      // height: 1.34rem;
      padding: 0 0.3rem;
      border-bottom: 1px #e5e5e5 solid;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      .label {
        width: 100%;
        height: 0.4rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: #999999;
        line-height: 0.4rem;
        margin-top: 0.3rem;
      }
      .txt {
        width: 100%;
        // height: .42rem;
        font-size: 0.3rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.42rem;
        padding: 0.08rem 0 0.08rem 0;
        word-break: break-all;
      }
    }
  }
  .handle-box {
    margin: 0 0.3rem 0.2rem 0.3rem;

    background: #ffffff;
    border-radius: 0.16rem;
  }

  .column-box {
    margin: 0 0.3rem 0.2rem 0.3rem;
    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.3rem;
    .column-name {
      margin-bottom: 0.2rem;
      font-weight: bold;
      word-break: break-word;
    }
    .column-content {
      overflow: hidden;
      /deep/ img {
        max-width: 100%;
        height: auto !important;
      }
      /deep/table {
        td {
          border: 1px solid #333;
        }
      }
    }
  }
  .btns {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.1rem 0.2rem;
    background: #fff;
  }
  .popup-contain {
    padding: 0.3rem 0.3rem;
    border-radius: 0.2rem;
    font-size: 0.3rem;
    line-height: 0.4rem;
    .go-zhb-guide {
      color: #3c37d2;
      border-bottom: 1px solid #3c37d2;
    }
  }
}
</style>
