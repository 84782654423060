<!-- 常见问题 -->
<template>
  <div class="relevant">
    <div v-if="detialInfo.itemQuestion" class="wrapper">
      <ArticleView :content="detialInfo.itemQuestion" />
    </div>
    <van-empty v-else description="数据为空"> </van-empty>
  </div>
</template>

<script>
import ArticleView from "@/components/ArticleView";
export default {
  props: {
    detialInfo: Object
  },
  data() {
    return {};
  },
  components: {
    ArticleView
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 0.3rem;
}
.iframe {
  width: 100%;
  min-height: 80vh;
}
</style>
